// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-convenios-js": () => import("./../../../src/pages/convenios.js" /* webpackChunkName: "component---src-pages-convenios-js" */),
  "component---src-pages-corpo-clinico-js": () => import("./../../../src/pages/corpo-clinico.js" /* webpackChunkName: "component---src-pages-corpo-clinico-js" */),
  "component---src-pages-exames-js": () => import("./../../../src/pages/exames.js" /* webpackChunkName: "component---src-pages-exames-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-o-alta-excelencia-diagnostica-js": () => import("./../../../src/pages/sobre-o-alta-excelencia-diagnostica.js" /* webpackChunkName: "component---src-pages-sobre-o-alta-excelencia-diagnostica-js" */),
  "component---src-pages-unidades-js": () => import("./../../../src/pages/unidades.js" /* webpackChunkName: "component---src-pages-unidades-js" */),
  "component---src-pages-vacinas-js": () => import("./../../../src/pages/vacinas.js" /* webpackChunkName: "component---src-pages-vacinas-js" */),
  "component---src-templates-basic-page-b-js": () => import("./../../../src/templates/basicPageB.js" /* webpackChunkName: "component---src-templates-basic-page-b-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-unity-js": () => import("./../../../src/templates/unity.js" /* webpackChunkName: "component---src-templates-unity-js" */)
}

